import { CircularProgress, CssBaseline, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lazy, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import appEndpoints from './App.endpoints';
import AppRoutes from './App.routing';
import * as actions from './AppContext/actions/actions';
import { iTheme, useAppContext } from './AppContext/App.context';
import { themeSettings } from './appTheme/theme';
import PageLoader from './components/pageLoader/pageLoader';
import HeaderSidebarWrapper from './components/Sidebar/HeaderSidebarWrapper';
import useToast from './components/Toast/hooks/useToast';
import Toast from './components/Toast/Toast';
import useLoader from './hooks/useLoader';
import useApiService from './services/api.service';
import useLocalStorage from './utils/localStorage';
import './App.scss';
import CommonEndpoints from './services/commonAPI.Service';
import { Provider } from 'react-redux';
import cmoDasboardStore from './store';
import { AppUtilityFunctions } from './utils/appUtils';
import {
	ThemeApp,
	useThemeContext,
} from './containers/Settings/GlobalSettings/GlobalSettingsTabs/ThemeContext';
import { palette } from '@mui/system';
import { LogoutSession } from './utils/logutSessionFromKeyCloak';
import EditProfileEndPoints from './containers/EditProfile/EditProfileEndpoints';
import { DateTypeProvider } from './containers/Reports/ComparisonReportNew/Components/DateTypeContext';

const exclusionArray = [
	'/login',
	'/chooseAccount',
	'/reset-password',
	'/register',
	'',
	'/account/',
	'/account',
];

const App = () => {
	const {
		primaryColorApp,
		handlePrimaryColorChange,
		secondaryColorApp,
		graphColorsApp,
		handleSecondaryColorChange,
		handleGraphColorChange,
	} = useThemeContext();
	const isSidebarVisible = exclusionArray.indexOf(window.location.pathname) < 0;
	const [appData, dispatch]: any = useAppContext();
	const { userData, theme: appThemeGlobalContext } = appData;
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const loader = useLoader();
	const APIService = useApiService();
	const toaster: any = useToast();
	let [isLoadingComplete, setIsLoadingComplete] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const excludedHosts = [
		'dev.digg.ai',
		'test.digg.ai',
		'beta.diggrowth.com',
		'app.diggrowth.com',
		'localhost:3000',
		'localhost:3001',
	];
	const isClient = !excludedHosts.some((host) =>
		window.location.host.includes(host)
	);
	useLocalStorage.setItem('isClient', isClient);

	const logoutSessionFromKeyCloak = async (refresh_token: any) => {
		loader.showLoader();
		let request = {
			refresh_token: refresh_token,
		};
		return await APIService.get(EditProfileEndPoints.logoutUser(request))
			.then((res: any) => {
				loader.hideLoader();
				return true;
			})
			.catch((err: any) => {
				loader.hideLoader();
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
				return false;
			});
	};

	const getKeyCloakAccessToken = (keyCloakCode: any, refresh?: any) => {
		const headers: any = {
			code: keyCloakCode,
			redirect_uri: AppUtilityFunctions.getRedirectUrl(),
		};
		const reqData: any = {
			request: {},
			headers: headers,
		};

		loader.showLoader();
		return APIService.get(appEndpoints.keyCloakAuth(reqData))
			.then(async (response: any) => {
				await hitApiBeforeAccessTokenExpire(response.data.expires_in);
				if (response?.data['access_token']) {
					let validateReponse = await validateAccessTokenWithKeyCloakServer(
						response?.data['access_token'],
						response?.data['refresh_token']
					);

					if (validateReponse.status == 200) {
						let tokenDetails = {
							access_token: response?.data['access_token'],
							refresh_token: response?.data['refresh_token'],
						};
						await getLoginDetailsFromToken(
							tokenDetails,
							validateReponse?.data['user_type'],
							refresh
						);
					}
				}
				loader.hideLoader();
			})
			.catch((err: any) => {
				LogoutSession.logoutSessionFromKeyCloak();
				window.location.href = '/login';
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
				loader.hideLoader();
			});
	};

	useEffect(() => {
		const url = new URL(window.location.href);
		const hubspotRedirectionUrl = url.searchParams.get('hubspot_redirect');
		if (hubspotRedirectionUrl) {
			useLocalStorage.setItem('hubspotRedirectionUrl', hubspotRedirectionUrl);
		}
	});

	const getClientLogo = async () => {
		const url = window.location.hostname;
		const getDomainName = (url: any) => {
			const domainRegex =
				/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/g;
			const match = domainRegex.exec(url);
			if (match && match.length > 1) {
				return match[1];
			}
			return null;
		};
		const headers = {
			url: getDomainName(url),
		};
		const reqData = {
			request: {},
			headers: headers,
		};
		return await APIService.get(appEndpoints.getLogos(reqData))
			.then(async (res: any) => {
				interface LocalStorageData {
					Favicon: any;
					LBLogo: any;
					LSLogo: any;
					DBLogo: any;
					DSLogo: any;
					DOSLogo: any;
					LOSLogo: any;
					title: any;
					url: any;
					email: any;
				}

				const localStorageData: LocalStorageData = {
					Favicon: res.data.FavLogo,
					LBLogo: res.data.LBLogo,
					LSLogo: res.data.LSLogo,
					DBLogo: res.data.DBLogo,
					DSLogo: res.data.DSLogo,
					DOSLogo: res.data.DOSLogo,
					LOSLogo: res.data.LOSLogo,
					title: res.data.org_name,
					url: res.data.redirect_uri,
					email: res.data.email,
				};
				useLocalStorage.setItem('Client_data', localStorageData);
				setData();
			})
			.catch((err: any) => {
				setDataLoaded(true);
			});
	};

	const getShareReportsLogo = async () => {
		const url = window.location.hostname;
		const getDomainName = (url: any) => {
			const domainRegex =
				/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/g;
			const match = domainRegex.exec(url);
			if (match && match.length > 1) {
				return match[1];
			}
			return null;
			1;
		};
		const headers = {
			url: getDomainName(url),
		};
		const reqData = {
			request: {},
			headers: headers,
		};
		return await APIService.get(appEndpoints.getShareReportLogos(reqData))
			.then(async (res: any) => {
				interface LocalStorageData {
					LBLogo: any;
					DBLogo: any;
				}

				const ShareData: LocalStorageData = {
					LBLogo: res.data.LBLogo,
					DBLogo: res.data.DBLogo,
				};
				useLocalStorage.setItem('ShareReports_data', ShareData);
			})
			.catch((err: any) => {
				setDataLoaded(true);
			});
	};

	// useEffect(() => {
	//     // if (isClient) {
	//         getColors(); // Call getColors when component mounts
	//     // }
	// }, []);
	const initializeAuthAndSettings = async () => {
		const { search } = window.location;
		const params = new URLSearchParams(search);
		const keyCloakCode = params.get('code');
		const hubspotRedirectionUrl = params.get('hubspot_redirect');
		const agencyToken = params.get('agencyToken');
		const type = params.get('user_type');
		const refresh_token = params.get('refresh_token');
		if (hubspotRedirectionUrl) {
			useLocalStorage.setItem('hubspotRedirectionUrl', hubspotRedirectionUrl);
		}
		if (agencyToken && window.location.pathname.includes('/account')) {
			let tokenDetails = {
				access_token: agencyToken,
				refresh_token: refresh_token,
			};
			await getLoginDetailsFromToken(tokenDetails, type, refresh_token);
			await getGlobalSettings(agencyToken);
			await getColors(agencyToken);
			await getLogo(agencyToken);
		}
		const embeded =
			params.get('embeded') || useLocalStorage.getItem('embeded') || false;

		if (!useLocalStorage.getItem('isAuthenticated')) {
			useLocalStorage.setItem('saveToRedirect', window.location.pathname);
		}
		useLocalStorage.setItem(
			'embeded',
			!!(embeded === true || embeded === 'true')
		);
		if (!useLocalStorage.getItem('appTheme')) {
			useLocalStorage.setItem('appTheme', 'dark');
		}

		const localStorageTheme = useLocalStorage.getItem('appTheme') || 'dark';
		dispatch(
			actions.onToggleTheme(
				localStorageTheme === 'dark' ? iTheme.DARK : iTheme.LIGHT
			)
		);

		if (keyCloakCode && window.location.pathname.includes('/login')) {
			// get keycloak authetication according to key cloak code
			getKeyCloakAccessToken(keyCloakCode, refresh_token);
		} else if (
			!useLocalStorage.getItem('isAuthenticated') &&
			window.location.pathname !== '/reset-password'
		) {
			setIsLoadingComplete(true);
			const { search } = window.location;
			const params = new URLSearchParams(search);
			let redirectURL: any = '';

			if (params.get('redirect_url')) {
				redirectURL = params.get('redirect_url');
			}
			navigate({
				pathname: '/login',
				search: redirectURL ? `?redirect_url=${redirectURL}` : '',
			});
		} else {
			let redirectURL: any = '';
			const token = userData.access_token;
			if (params.get('redirect_url')) {
				redirectURL = params.get('redirect_url');
			}
			if (redirectURL.trim() && token) {
				window.location.href = `${redirectURL}?access_token=${token}`;
				return;
			}
			setIsLoadingComplete(true);
		}
		localStorage.setItem('isInsightsOpened', 'false');
	};
	useEffect(() => {
		if (isClient && window.location.pathname !== '/login') {
			getClientLogo();
			getShareReportsLogo();
		}
		function changePrimaryTheme(newColor: any) {
			document.documentElement.style.setProperty('--primary-theme', newColor);
		}
		function changeGraphTheme(newColor: any) {
			document.documentElement.style.setProperty(
				'--funnel-color-1',
				`90px solid ${newColor[0]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-2',
				`90px solid ${newColor[1]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-3',
				`90px solid ${newColor[2]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-4',
				`90px solid ${newColor[3]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-5',
				`90px solid ${newColor[4]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-6',
				`90px solid ${newColor[5]}`
			);
			document.documentElement.style.setProperty(
				'--funnel-color-7',
				`90px solid ${newColor[6]}`
			);
		}
		changePrimaryTheme(localStorage.getItem('primaryColor'));
		changeGraphTheme(graphColorsApp);
		initializeAuthAndSettings();
	}, []);

	const getColors = async (token: any) => {
		const headers = {
			'access-token': token,
		};
		const reqData = {
			request: {},
			headers: headers,
		};
		return await APIService.get(appEndpoints.getColorTheme(reqData), true)
			.then(async (res: any) => {
				const primaryTheme = res?.data?.color_setting?.primaryTheme;
				const secondaryTheme = res?.data?.color_setting?.secondaryTheme;
				const graphTheme = res?.data?.color_setting?.graphTheme;
				const custom = res?.data?.color_setting?.Custom;
				localStorage.setItem('themeOption', custom);
				handlePrimaryColorChange(primaryTheme);
				handleSecondaryColorChange(secondaryTheme);
				handleGraphColorChange(graphTheme);
			})
			.catch((err: any) => {
				return [];
			});
	};
	const getGlobalSettings = async (token: any) => {
		const headers = {
			'access-token': token,
		};
		const reqData = {
			request: {},
			headers: headers,
		};
		return await APIService.get(
			CommonEndpoints.getGlobalSettings(reqData),
			true
		)
			.then(async (res: any) => {
				useLocalStorage.setItem('global_settings', res?.data);
			})
			.catch((err: any) => {
				return [];
			});
	};

	const getLogo = async (token: any) => {
		const headers = {
			'access-token': token,
		};
		const reqData = {
			request: {},
			headers: headers,
		};
		return await APIService.get(CommonEndpoints.getLogoUrl(reqData), true)
			.then(async (res: any) => {
				useLocalStorage.setItem('logo', res?.data);
			})
			.catch((err: any) => {
				return [];
			});
	};

	const generateAccessTokenWithRefreshToken = async () => {
		if (userData) {
			let refreshToken = userData['refresh-token'];
			if (refreshToken) {
				let apiUrl =
					process.env.REACT_APP_API_URL +
					`kcauth/refreshToken?refresh_token=${refreshToken}`;
				try {
					const response = await fetch(apiUrl, {
						method: 'GET',
					});
					if (response.status === 440) {
						LogoutSession.logoutSessionFromKeyCloak;
						window.location.href = '/login';
						const Client_Data = localStorage.getItem('Client_data') || '';
						localStorage.clear();
						localStorage.setItem('Client_data', Client_Data);
					} else {
						const responseData = await response.json();
						if (userData && responseData) {
							userData['refresh-token'] = responseData.data.refresh_token;
							userData['access-token'] = responseData.data.access_token;
							localStorage.setItem('userData', JSON.stringify(userData));
							hitApiBeforeAccessTokenExpire(responseData.data.expires_in);
						} else {
							LogoutSession.logoutSessionFromKeyCloak;
							window.location.href = '/login';
							const Client_Data = localStorage.getItem('Client_data') || '';
							localStorage.clear();
							localStorage.setItem('Client_data', Client_Data);
						}
					}
				} catch (error) {
					const Client_Data = localStorage.getItem('Client_data') || '';
					localStorage.clear();
					localStorage.setItem('Client_data', Client_Data);
					LogoutSession.logoutSessionFromKeyCloak;
					window.location.href = '/login';
					// Handle any fetch errors here
					console.error('Fetch error:', error);
				}
			}
		}
	};
	const Data = useLocalStorage.getItem('Client_data');
	const title: any = Data?.title;
	const faviconimg: any = Data?.Favicon;
	const isClients = useLocalStorage.getItem('isClient');
	const setData = () => {
		let str: any = title?.replace(/"/g, '');
		document.title = isClients ? str : 'Diggrowth';
		const favicon: any = document.getElementById('favicon');
		let fav: any = faviconimg?.replace(/"/g, '');
		favicon.href = isClients
			? fav
			: 'https://digg-gn.s3.us-west-2.amazonaws.com/white_label_logo/DefaultFavicon.png';
		// setDataLoaded(true);
	};

	useEffect(() => {
		if (useLocalStorage.getItem('isAuthenticated')) {
			setData();
		}
		let localTokenExpiryTime: any = useLocalStorage.getItem('tokenExp');
		if (localTokenExpiryTime) {
			let tokenExpiryTime =
				AppUtilityFunctions.parseDateStringToDate(localTokenExpiryTime);
			let currentDateTime: any = new Date();
			let differnceInSeconds =
				AppUtilityFunctions.calculateTimeDifferenceInSeconds(
					tokenExpiryTime,
					currentDateTime
				);
			if (differnceInSeconds) {
				hitApiBeforeAccessTokenExpire(differnceInSeconds);
			}
		}
	}, []);

	const hitApiBeforeAccessTokenExpire = (expiryTime: any) => {
		let expiryMinutes = expiryTime / 60; // convert time into minutes
		let expiryDateAndTime: any =
			AppUtilityFunctions.getCurrentDateTimeWithAddedMinutes(expiryMinutes);
		localStorage.setItem('tokenExp', expiryDateAndTime);
		// convert expiry minutes into milli seconds
		let expiryDateTimeFormat: any =
			AppUtilityFunctions.parseDateStringToDate(expiryDateAndTime);
		let currentDateTime: any = new Date();
		let timeDiffernceInMilliSeconds: any =
			expiryDateTimeFormat - currentDateTime;
		let deduction = (timeDiffernceInMilliSeconds * 10) / 100;
		let deductedMilliSeconds = timeDiffernceInMilliSeconds - deduction;
		if (deductedMilliSeconds) {
			setTimeout(() => {
				generateAccessTokenWithRefreshToken();
			}, deductedMilliSeconds);
		}
	};

	const validateAccessTokenWithKeyCloakServer = (
		accessToken: any,
		refresh_token: any
	) => {
		const headers: any = {
			'access-token': accessToken,
		};
		const reqData: any = {
			request: {},
			headers: headers,
		};

		loader.showLoader();
		return APIService.get(appEndpoints.validateAccessToken(reqData))
			.then(async (response: any) => {
				return response;
			})
			.catch((err: any) => {
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
				logoutSessionFromKeyCloak(refresh_token);
				loader.hideLoader();
				return false;
			});
	};

	const getLoginDetailsFromToken = (
		tokenDetails: any,
		userType?: any,
		refresh?: any
	) => {
		const headers: any = {
			'access-token': tokenDetails.access_token,
		};
		const reqData: any = {
			request: {},
			headers: headers,
		};
		return APIService.get(appEndpoints.getLoginDetails(reqData))
			.then(async (response: any) => {
				const res = response.data;
				const resData: any = {
					email: res.email,
					name: `${`${res.first_name} ${res.last_name}`}` || 'Digg User',
					platform: '',
					attribute: '',
					parent_id: res.parent_id || 0,
					'refresh-token': tokenDetails.refresh_token || '',
					'access-token': tokenDetails.access_token,
					user_id: res.user_id || '',
					selected_platform: res.selected_platform,
					connector_count: res.connector_count,
					login_count: res?.login_count,
					reportName: res?.hb_report_name,
					user_type: userType || 0,
				};
				useLocalStorage.setItem(
					'isCustomizedSideBar',
					res.is_custom_side_bar_enable
				);
				const minimizeSettings = {
					fb_minimize: res.fb_minimize,
					inst_minimize: res.inst_minimize,
					youtube_minimize: res.youtube_minimize,
					linkedin_minimize: res.linkedin_minimize,
				};
				useLocalStorage.setItem('minimizeSettings', minimizeSettings);
				useLocalStorage.setItem('Deleted_User', res.deleted_user);
				useLocalStorage.setItem(
					'isCustomDashboardEnable',
					res?.toggle_custom_dashboard || false
				);
				let tempConnectedCrmArray = [];
				if (res?.connected_crms?.includes('Hubspot_Crm')) {
					tempConnectedCrmArray.push(19);
				}
				if (res?.connected_crms?.includes('Google Analytics')) {
					tempConnectedCrmArray.push(5);
				}
				if (res?.connected_crms?.includes('Salesforce')) {
					tempConnectedCrmArray.push(4);
				}
				useLocalStorage.setItem('connectedCRM', tempConnectedCrmArray);
				if (res?.is_custom_side_bar_enable == 1) {
					useLocalStorage.setItem('sideBarSectionData', res?.custom_nav_bar);
				}
				useLocalStorage.setItem('isAuthenticated', true);
				let themeType = 'dark';
				if (res.dark_theme == 0) {
					themeType = 'light';
				}
				useLocalStorage.setItem('appTheme', themeType);

				if (res?.response !== 'parameter') {
					resData.platform = res.parameter;
					resData.attribute = res.attribute;
				}
				useLocalStorage.setItem('userData', resData);
				await getGlobalSettings(tokenDetails.access_token);
				await getColors(tokenDetails.access_token);
				await getLogo(tokenDetails.access_token);
				let rediretRoute =
					res.is_custom_side_bar_enable == 1
						? res?.default_route
						: '/insights/cmo/dashboard';
				useLocalStorage.setItem('defaultRoute', rediretRoute);
				resData.user_type == 1 && !refresh
					? (window.location.href = '/account')
					: (window.location.href = rediretRoute);

				isLoadingComplete = true;
				setIsLoadingComplete(true);

				loader.hideLoader();
			})
			.catch((err: any) => {
				toaster.addToast({
					message: err.message || 'Something went wrong',
					timeout: 2000,
					type: 'error',
				});
				loader.hideLoader();
			});
	};

	const embeded = useLocalStorage.getItem('embeded') || false;
	const mode = appThemeGlobalContext || 'dark';

	let theme: any = useMemo(
		() =>
			createTheme(
				themeSettings(mode, primaryColorApp, secondaryColorApp, graphColorsApp)
			),
		[mode, primaryColorApp, secondaryColorApp, graphColorsApp]
	);
	return (
		<DateTypeProvider>
			<Provider store={cmoDasboardStore}>
				<ThemeProvider theme={theme}>
					{!loading ? (
						<div
							className={isSidebarVisible ? 'app' : ''}
							style={{ backgroundColor: theme.palette.background.main }}
						>
							{isSidebarVisible ? <HeaderSidebarWrapper /> : null}
							<div
								className={
									isSidebarVisible
										? !embeded
											? 'app__outer'
											: 'app__outer app__embeded'
										: ''
								}
								style={theme.palette.outerAppStyle}
							>
								{appData.onLoaded?.showLoader ? (
									<PageLoader fullWidth={true} />
								) : null}
								{isLoadingComplete ? <AppRoutes /> : null}
							</div>
						</div>
					) : (
						<Grid
							item
							style={{
								width: '100%',
								height: '50vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CircularProgress
								size={50}
								style={{ color: theme.palette.text.tertiary, zIndex: 9999 }}
							/>{' '}
						</Grid>
					)}
					<Toast />
					<CssBaseline />
				</ThemeProvider>
			</Provider>
		</DateTypeProvider>
	);
};
App.whyDidYouRender = true;

export default App;
